import MessageMap from '../i18n';

const frFR: MessageMap = {
    test: {
        translate: 'Traduire',
        missing: "Seule l'option anglaise est disponible",
    },
    sidebar: {
        requests: 'Demandes',
    },
    breadcrumbs: {
        requests: 'Demandes',
        requestDetails: 'Détails de la demande',
    },
    pendingRequestsPage: {
        pendingRequest: 'Demandes en attente',
        results: 'Affichage de {resultsNumber} résultats',
        title: 'Titre',
        createdOn: 'Créé le',
        vendor: 'Fournisseur',
        country: 'Pays',
        tool: 'Outil',
        id: 'ID',
        requester: 'Demandeur',
        pagination: { pageSizeOptions: 'Éléments par page: {options}' },
        emptyMessage: "Aucune information à afficher",
        commom: {
            filter: 'Filtrer',
            search: 'Rechercher par titre, ID ou demandeur',
        },
    },
    resolvedRequestsPage: {
        resolvedRequest: 'Demandes résolues',
        results: 'Affichage de {resultsNumber} résultats',
        title: 'Titre',
        status: 'Statut',
        createdOn: 'Créé le',
        lastEdited: 'Dernière modification',
        vendor: 'Fournisseur',
        country: 'Pays',
        tool: 'Outil',
        id: 'ID',
        requester: 'Demandeur',
        approver: 'Approbateur',
        pagination: { pageSizeOptions: 'Éléments par page: {options}' },
        commom: {
            filter: 'Filtrer',
            search: 'Rechercher par titre, ID ou demandeur',
        },
        emptyMessage: "Aucune information à afficher",
    },
    statusRequests: {
        approved: 'Approuvé',
        failed: 'Échec',
        processing: 'Traitement',
        rejected: 'Rejeté',
        pending: 'En attente',
        canceled: 'Annulé',
        pendingInfo: 'Cette demande est en attente d’examen.',
        failedInfo: "La demande a été approuvée, mais en raison d’une erreur système, elle n’a pas été complétée.",
        processingInfo: 'L’approbation de cette demande est en cours de traitement.',
        rejectedInfo: 'Cette demande a été rejetée.',
        approvedInfo: 'Cette demande a été approuvée.',
        canceledInfo: 'Cette demande a été annulée.',
    },
    tabs: {
        pending: 'En attente',
        resolved: 'Résolu',
    },
    detailsContainer: {
        requestDetails: 'Détails de la demande',
        vendor: 'Fournisseur',
        country: 'Pays',
        requesters: 'Demandeur',
        sharedWith: 'Partagé avec',
        showMore: 'Afficher plus',
        tool: 'Outil',
        createdOn: 'Créé le',
        approver: 'Approbateur',
        lastEdited: 'Dernière modification',
        id: 'ID',
        requestLink: "Ouvrir la demande d'URL",
    },
    approvalRequest: {
        error: 'Erreur',
        errorMessage: 'Un problème est survenu. Veuillez réessayer.',
        rejectedSuccessfully: 'Demande rejetée avec succès.',
        approveSuccessfully: 'Votre approbation a été soumise et est en cours de traitement.',
        modal: {
            approveButton: 'Approuver',
            rejectButton: 'Rejeter',
            cancelButton: 'Non, retour',
            approveAccept: 'Oui, approuver',
            approveDescription: 'Approuver la demande?',
            approveTitle: 'Approuver la demande?',
            rejectTitle: 'Rejeter la demande?',
            acceptWarn: 'Une fois que vous l’avez approuvée, vous ne pouvez pas annuler cette action.',
            rejectAccept: 'Oui, rejeter',
            rejectReason: 'Ajouter un motif de rejet. Vous ne pourrez pas annuler cette action.',
            reasonTextArea: 'Raison:',
            rejectionError: 'Vous devez saisir la raison du rejet.',
        },
        table: {
            information: 'Informations',
            currentValue: 'Valeur actuelle',
            newValue: 'Nouvelle valeur',
            deletedValue: 'Valeur supprimée',
            noValue: 'Aucune valeur',
            removed: 'Valeur supprimée',
            removedTooltip: 'Ces informations ont été supprimées par le demandeur.',
            noValueTooltip: 'Ce champ était vide avant cette demande.',
            noValueProvidedTooltip: 'Aucune information fournie.',
        },
        informationContainer: {
            requestType: 'Type de demande',
            infoToReview: 'Informations à examiner',
            reviewedInfo: 'Informations examinées',
            rejectReason: 'Raison du rejet',
            processingAlert: 'L’approbation de cette demande est en cours de traitement.',
            failedAlert: "La demande a été approuvée, mais en raison d’une erreur système, elle n’a pas été complétée.",
            canceledAlert: "La demande a été annulée. Les actions ou les modifications demandées n’ont pas été mises en œuvre.",
            requestTypeName: {
                update: 'Mettre à jour',
                insert: 'Créer',
                delete: 'Supprimer',
            },
        },
    },
    filter: {
        status: 'Statut',
        vendor: 'Fournisseur',
        vendors: 'Fournisseurs',
        tool: 'Outil',
        tools: 'Outils',
        createdOn: 'Créé le',
        lastEdited: 'Dernière modification',
        startDate: 'Date de début',
        endDate: 'Date de fin',
        endDateHint: 'Laissez ce champ vide pour rechercher uniquement par la date de début.',
        endDateError: 'La date de fin doit être postérieure à la date de début.',
        invalidDateError: 'Date invalide.',
        clearFilters: 'Effacer les filtres',
        applyFilters: 'Appliquer',
        cancel: 'Annuler',
        search: 'Recherche',
    },
    table: {
        loading: "Chargement d'éléments...",
    },
    pagination: {
        of: 'de',
    },
};

export default frFR;
